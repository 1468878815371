(function() {
  'use strict';

  var salonURL = window.location.search;
  var urlString = function(name) {
    var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(salonURL.toLowerCase());
    if (results == null) {
      return 0;
    } else {
      return decodeURI(results[1]) || 0;
    }
  };

  var params = {};

  params.salon_id = urlString('salonid') || '';
  params.artist_id = urlString('artistid') || '';
  params.socialshoppe = urlString('socialshoppe') || '';
  params.label = urlString('label') || '';
  params.force = urlString('force') || '';
  params.look_id = urlString('lookid') || '';

  if (params.salon_id !== '') {
    for (var param in params) {
      if (params[param] === '') {
        delete params[param];
      }
    }
    generic.jsonrpc.fetch({
      method: 'user.setSessionSalonCommissionData',
      params: [params]
    });
  }
})();
